.container {
  display: flex;
  flex-direction: column;
  height: auto; // 컨테이너의 높이를 자동으로 조정
  max-height: 100vh; // 최대 높이를 화면의 높이로 제한
  width: 100%;

  .chat_window {
    overflow-y: auto;
    padding: 10px;
    color: aliceblue;
    flex-grow: 1; // 사용 가능한 공간을 모두 차지하도록 설정
  }

  form {
    display: flex;
    width: 100%;

    .chat_input {
      display: flex;
      height: 30px;
      background-color: #222;
      color: #fff;
      border: 0;
      border-radius: 5px;
      margin: 5px;
      padding: 5px;
      box-sizing: border-box;
      width: 100%;
    }
  }
}
